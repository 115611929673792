<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <product-item-update :id="id" @gotAttrs="assignAttrs"/>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ProductItemUpdate from './ProductItemUpdate.vue'

export default {
  components: {
    VuexBreadcrumb,
    ProductItemUpdate
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        product_model_ids: this.id
      },
      ams_template: {
        type: 'product-listings',
        description: undefined,
        attributes: {
          'product-model-id': this.id,
          'price-cents': 0
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description + ' (copy) ' + attrs['serial-number']
    }
  }
}
</script>